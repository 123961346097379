"use strict"


$(function() {
  var _window = $(window),
  mqMenu = '77em',
  isSetDown = false,
  isSetUp = false;

  setHeaderFixed();

  _window.resize(function() {
    setHeaderFixed();
  });


  function setHeaderFixed(){
    if (!Modernizr.mq('(min-width: '+mqMenu+')')) {

      _window.on('scroll', function(){

        if(_window.scrollTop() > getMenuHeight()){

          if(!isSetDown){
            $('.js-header').addClass('header--is--scroll');
            $('.js-app').addClass('app--header--scroll');
            isSetUp = false;
            isSetDown = true;
          }
        }else{

          if(!isSetUp){
            $('.js-header').removeClass('header--is--scroll');
            $('.js-app').removeClass('app--header--scroll');
            isSetDown = false;
            isSetUp = true;
          }
        }

      });
    }
  }

  function getMenuHeight(){
    return $('.js-dashboard__menu').height();
  }

});





function scrollWindow(id, speed) {
  var page = $("html, body");

  var header = 0;

  page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function () {
    page.stop();
  });

  page.animate({scrollTop: $(id).offset().top - header}, speed, function () {
    page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
  });

}

$(function() {
  $('.js-scroll').on('click', function(e){
    e.preventDefault();

    if($(this).data('is-tab')){
      var href = $(this).data('scroll-id');
      $('[data-toggle="tab"][href$="'+href+'"]').trigger('click');
    }
    scrollWindow($(this).data('scroll-id'));
  });
});


$(function() {
  $('.js-map').click(function() {
    $(this).find('iframe').toggleClass('scroll-off');
  });
  $('.js-map').on("mouseleave", function() {

    $(this).find('iframe').addClass('scroll-off');
  });
});


$(function() {
  var d = new Date();
  $('.js-actual-year').append(d.getFullYear());
});

$(function() {

  $('[data-toggle="tab"]').on('click', function(event) {
    event.preventDefault();
    var tabActiveClass = 'tab__item--is--active';
    if($(this).hasClass('lang-tab__link')){

      tabActiveClass = 'lang-tab__item--is--active';
    }

    $(this).parent().siblings().children().attr('aria-selected', 'false');
    $(this).attr('aria-selected', 'true');

    $(this).parent().siblings().removeClass(tabActiveClass);
    $(this).parent().addClass(tabActiveClass);
    var tab = $(this).attr('href');

    $(this).parent().parent().parent().siblings().children().not(tab).removeClass('tab__pane--is--active');
    $(tab).addClass('tab__pane--is--active');
  });

});


function detectIE() {
  var ua = window.navigator.userAgent;


  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  return false;
}


function swimmerForm() {
  return new Promise(function (resolve, reject) {

    $.nette.ext('plavciForm', {
      success: function (response) {
        resolve(response)

      },
      error: function (err) {
        reject(err);
      }

    });

  });
}


$(function() {
  var clipboard =  new ClipboardJS('.js-copy');
  clipboard.on('success', function(e) {

    $('.js-toggle-copy').fadeIn();
    setTimeout(function(){
      $('.js-toggle-copy').fadeOut();
    }, 1000);
    e.clearSelection();
  });
});
